var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"cancel-variant":"outline-secondary","ok-title":_vm.$t('Сохранить'),"no-close-on-backdrop":"","cancel-title":_vm.$t('Отмена'),"centered":"","title":_vm.title},on:{"ok":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2 row"},[_c('b-form-group',{staticClass:"col-12",attrs:{"label":_vm.$t('Именование'),"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 3 ? false:null,"name":"name","placeholder":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-12",attrs:{"label":_vm.$t('Адрес'),"label-for":"address"}},[_c('validation-provider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address","state":errors.length > 3 ? false:null,"name":"name","placeholder":""},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-12",attrs:{"label":_vm.$t('Директор ФИО'),"label-for":"director_fio"}},[_c('validation-provider',{attrs:{"name":"director_fio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"director_fio","state":errors.length > 3 ? false:null,"name":"director_fio","placeholder":""},model:{value:(_vm.form.director_fio),callback:function ($$v) {_vm.$set(_vm.form, "director_fio", $$v)},expression:"form.director_fio"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-6",attrs:{"label":_vm.$t('Телефон'),"label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_vm._v(" +998 ")]),_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('#########'),expression:"'#########'"}],attrs:{"id":"v-phone","name":"v-phone","state":errors.length > 3 ? false:null},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-6",attrs:{"label":_vm.$t('ИНН'),"label-for":"tin"}},[_c('validation-provider',{attrs:{"name":"tin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('#########'),expression:"'#########'"}],attrs:{"id":"tin","type":"number","state":errors.length > 3 ? false:null,"name":"tin","placeholder":""},model:{value:(_vm.form.tin),callback:function ($$v) {_vm.$set(_vm.form, "tin", $$v)},expression:"form.tin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-6",attrs:{"label":_vm.$t('Рассчетный счет'),"label-for":"account_number"}},[_c('validation-provider',{attrs:{"name":"account_number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('####################'),expression:"'####################'"}],attrs:{"id":"account_number","state":errors.length > 3 ? false:null,"name":"account_number","type":"number","placeholder":""},model:{value:(_vm.form.account_number),callback:function ($$v) {_vm.$set(_vm.form, "account_number", $$v)},expression:"form.account_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-6",attrs:{"label":_vm.$t('Банк'),"label-for":"bank_name"}},[_c('validation-provider',{attrs:{"name":"bank_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"bank_name","state":errors.length > 3 ? false:null,"name":"bank_name","placeholder":""},model:{value:(_vm.form.bank_name),callback:function ($$v) {_vm.$set(_vm.form, "bank_name", $$v)},expression:"form.bank_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-6",attrs:{"label":_vm.$t('МФО Банка'),"label-for":"mfo"}},[_c('validation-provider',{attrs:{"name":"mfo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('#####'),expression:"'#####'"}],attrs:{"id":"mfo","type":"number","state":errors.length > 3 ? false:null,"name":"mfo","placeholder":""},model:{value:(_vm.form.mfo),callback:function ($$v) {_vm.$set(_vm.form, "mfo", $$v)},expression:"form.mfo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }